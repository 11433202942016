<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-sm-10 col-12' :'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center mb-3">
        <h1 class="mb-1">{{ $t('creator.checkLogo') }}</h1>
        <p class="mb-2">{{ $t('creator.fromCastingCall') }}</p>

        <div class="d-flex align-items-center justify-content-center mb-2">
          <b-avatar class="mr-1" icon="shop" :src="brand_selected.logo ? utils.getAssetUrl(brand_selected.logo) : brand_selected.logo_url"></b-avatar>
          <div class="text-left">
            <strong>{{brand_selected.name}}</strong>
            <p class="avenir-medium m-0 break-all-word">{{brand_selected.domain}}</p>
          </div>
        </div>

        <div class="">
          <p class="m-0">{{ $t('creator.needUpdate') }}</p>
          <b-link  class="link-back" @click="$router.push({name: 'steps_casting_call', params: {slug: $route.params.slug, step: 'organization-search'}})">{{ $t('creator.searchBrand') }}</b-link>
        </div>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" class="blue-button-next-step" :disabled="selected === null" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>
      <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.brandRequired') }}</small>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BAvatar,
} from 'bootstrap-vue';
import utils from '@/libs/utils'
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepOrganization',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BAvatar,
  },
  data() {
    return {
      utils,
      selected: [],
      content_type: {
        text: ''
      },
      steps: {},
      casting_call: {},
      user_data: utils.getUserData(),
      brand_selected: {}
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getData() {
      this.steps = JSON.parse(localStorage.getItem('steps')) === null ? {} : JSON.parse(localStorage.getItem('steps'));
      if (this.has_slug) {
        casting_calls_services.getCastingCallBySlug(this.has_slug).then((response) => {
          this.steps.uuid = response.uuid;
          localStorage.setItem('steps', JSON.stringify(this.steps));
          this.casting_call = response;
          this.brand_selected = response.brand;
          if (this.steps.brand) this.brand_selected = this.steps.brand;
        });
      } else if (this.steps.brand) this.brand_selected = this.steps.brand;
      else {
        this.brand_selected = {
          name: this.user_data.account_company.main_brand.name,
          domain: this.user_data.account_company.main_brand.domain,
          logo: this.user_data.account_company.main_brand.logo,
          uuid: this.user_data.account_company.main_brand.uuid,
        }
      }
    },
    save() {
      if (this.has_slug) {
        const form_data = new FormData();
        form_data.append('brand_uuid', (this.steps && this.steps.brand) ? this.steps.brand.uuid : this.brand_selected.uuid);
        casting_calls_services.updateCastingCall(this.steps.uuid, form_data).then((response) => {
          this.$emit('update_campaign', response.response);
        });
      } else {
        this.steps['brand'] = this.brand_selected;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({ name: 'steps_casting_call', params: {step: 'title-casting-call'}})
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.break-all-word {
  word-break: break-all;
}
</style>